@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap');
* {
   padding: 0; 
   margin: 0;
   box-sizing: border-box;
}

:root {
    --primary-color: #2f3352;
    --secondary-color: #2d3758;
    --subSecondary-color: #00FF00;
    --font-color: #f88c49;
}

body {
  background-color: var(--primary-color);
  font-size: 16px;
  font-family: 'Gilda Display', serif;
  color: var(--font-color);
  overflow: hidden auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(88 90 116) inset !important;
    -webkit-text-fill-color: #fff !important;
}