.boxs {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px 0 20px;
}
.boxs h1 {
    color: var(--font-color);
    font-size: 20px;
    margin-bottom: 15px;
}
.boxs select {
    padding: 10px 60px;
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    outline: none;
    color: #000;
    -webkit-appearance: none;
     -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: 10px;
}
.boxs input {
    padding: 10px 60px 10px 20px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    margin-bottom: 15px;
    background-color: transparent;
    border: 1px solid var(--font-color);
}
.boxs input::placeholder {
    color: var(--font-color);
}
.table {
    width: 100%;
    border-collapse: collapse;
}

.table td {
    border: 1px solid #e8e8e8;
    border-width: 0.2px;
    color: #e8e8e8;
    font-weight: 300;
    width: 50%;
    text-align: center;
    padding: 5px;
}
.table td:last-child {
    color: var(--font-color);
}
.logout {
    background-color: var(--font-color);
    color: #FFF;
    padding: 10px 0;
    width: 100%;
    margin: 20px 0;
    border-radius: 5px;
}
.switchContainer {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 36px;
    margin-left: 20px;
}
.switchInput {
    display:none;
    &:checked + .slider {
        background-color: #2ab934;
    }
    &:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(55px);
    }
    &:checked + .slider:after {
        content:'ON';
    }
}
.switchToggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;

    &:before {
        position: absolute;
        content: "";
        height: 32px;
        width: 32px;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: white;
        transition: 0.4s;
        border-radius: 6px;
    }
    &:after {
        content:'OFF';
        color: white;
        display: block;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
    }
}